html {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
}

#root, #app-wrapper {
    height: 100%;
}

.appMenuItem {
    text-decoration: none;
    color: inherit;
}

.appMenuItemText > .MuiTypography-root {
    font-weight: bold !important;
    font-family: CarroisGothicSC, "Helvetica", "Arial", sans-serif !important;
    color: black !important;
}

.appTableActionButton {
    padding: 4px !important;
    color: black !important;
}


.MuiIconButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important
}