* {
    font-family: CustomRoboto, Helvetica, sans-serif !important;
}

@font-face {
    font-family: 'CustomRoboto';
    src: url('../assets/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'CarroisGothicSC';
    src: url('../assets/CarroisGothicSC-Regular.ttf') format('truetype');
}

