.leaflet-popup-content {
  min-width: 300px !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inspmap {
  min-height: 90vh;
  height: 100%;
  width: 100%;
  margin: auto;
  position: relative;
}

@media screen and (max-width: 767px){
  .inspmap {
    min-height: 80vh;
  }

}
.ui.grid .column .ui.tiny.statistic .label {
  opacity: 0.6!important;
}
.ui.grid .column .ui.mini.statistic .label {
  opacity: 0.6!important;
}
/*.ui.grid .column .ui.mini.statistic .value {*/
/* font-size: 10px!important;*/
/*}*/
i.icon {
  font-family: Icons !important;
}

.leaflet-popup-tip-container {
  /* bottom: -12% !important; */
}

.ui.grid .four.wide.column {
  padding-right: 0px;
  /* margin-right: 0px; */
}

.stretched.twelve.wide.column {
  padding-left: 0.5px;
  border-left: 0px;

  /* margin-left: 0; */
}
.ui.bottom.attached.segment.active.tab {
  border-left: 0px;

  /* padding-left: 0px; */
}

.ui.bottom.attached.segment.active.tab {
  padding-left: 3rem;
}

@media screen and (max-width: 560px) {
  .ui.bottom.attached.segment.active.tab {
    padding-left: 1rem;
    text-align: center!important;
    border: 0;
    border-top: 1px solid #d4d4d5!important;
    width: 100%;
    margin: auto;
    background-color: transparent;

  }
  .ui.fluid.vertical.tabular.menu {
    border:0;
  }
  .tabgrid .ui.grid {
    flex-direction: column;
  }
  .tabgrid .four.wide.column {
    width:auto!important;
    margin:auto!important;
  }
  .tabgrid .active.item {
    border:0!important;
    border-left:1px solid #d4d4d5!important;
    border-right:1px solid #d4d4d5!important;
    border-radius: unset!important;
  }
  .tabgrid .stretched.twelve.wide.column{
    width: 100%;
    margin: auto;
  }
  .tabgrid .ui.fluid.vertical.tabular.menu {
    display: flex;
    flex-direction: row;
    border:1px solid #d4d4d5;
  }
}
@media screen and (max-width: 390px) {
  .tabgrid .item {
    padding:0.2rem!important;
    text-align: center;
    vertical-align: center;
    margin:auto;
  }
  .tabgrid .active.item {
    margin:auto!important;
  }

}
.react_time_range__tick_label {
  color:black!important;
  font-size: 9px!important;
  opacity: 0.8;
  /*font-weight: bold;*/
  /*transform: translateY(-160%);*/
}
.react_time_range__time_range_container {
  width:100%!important;
  padding-left: 1rem!important;
  padding-right: 1rem!important;

}
.ui .left .center .popup .transition .visible {
  background: transparent!important;
}

#intervalBt {
  background-color: transparent!important;
}
#intervalBt:hover {
  /*background-color: !important;*/
}
.datepicker {
  min-width: 120px!important;
  width: 100%!important;
  padding:0;
}
.datepicker .ui.fluid.icon.input {
  height: 100%;
}
.filter-container {
  display:flex;
  flex-direction: row;
}
@media screen and (max-width:900px) {
  .filter-container {
    flex-direction: column;
  }
}
.leaflet-control {
  margin:0!important;
  padding:0!important;
}
.leaflet-control-attribution.leaflet-control {
  display: none;
}

.ui[class*="right labeled"].input>input {
  border-top-right-radius: 5px!important;
 border-bottom-right-radius: 5px!important;
 border-right-color: rgba(34,36,38,.15)!important;
}

.custom-label {
  height: 100%!important;
  font-size: 14px!important;
  text-align: center;
  vertical-align: center;
  margin:auto;
}
.font12 {
  font-size: 12px!important;
}
.visible .menu .transition {
  z-index: 99999!important;
}
.leaflet-bottom.leaflet-left .leaflet-control .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded .ui.button {
  /*background: #7def7f;*/
  background: white!important;
  color: #969696;
}
.ui.button {
  font-weight: bold!important;
}
.leaflet-bottom.leaflet-left .leaflet-control .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
  background: transparent!important;
  border: none!important;
  box-shadow: none!important;
}
h3 {
  /*color:#00887A!important;*/
  opacity: 0.9!important;
}

.ui.horizontal.segments {
  padding:0!important;
  margin:0!important;
  border: 0!important;
  box-shadow: none!important;
}
.ui.horizontal.segments>.segment {
  border:0!important;
  box-shadow: none!important;
}

.ui.basic.left.floated.segment .ui.medium.icon.header {
  font-family: initial!important;
}
.ui.basic.center.aligned.segment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui.basic.center.aligned.segment .ui.medium.icon.header {
  font-family: Arial, Helvetica, sans-serif !important;
  margin: auto;
  font-size: 24px;
}


.apv-popup {
  /* display: flex; */
  flex-direction: column;
}

.apv-popup p {
  margin: .8rem !important;
  text-align: center !important;
}

