.decorated{
    overflow: hidden;
    text-align: center;
    color: #67757c;
    margin: 2em 4em 2em 4em;
}

.decorated > span{
    position: relative;
    display: inline-block;
}

.decorated > span:before, .decorated > span:after{
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 1px solid;
    width: 592px;
    margin: 0 20px;
    color: #67757c;
}

.decorated > span:before{
    right: 100%;
}

.decorated > span:after{
    left: 100%;
}
